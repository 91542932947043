import { BaseHTMLAttributes, lazy, LazyExoticComponent, Suspense } from "react";
import styled, { css } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import capitalize from "utilities/capitalize";
import { Color } from "theme";
import Loading from "./Loading";
import decapitalize from "utilities/decapitalize";

export const icons = [
  "search",
  "arrowRight",
  "arrowLeft",
  "bell",
  "snapchat",
  "facebook",
  "instagram",
  "linkedin",
  "twitter",
  "tiktok",
  "pen",
  "checkCircle",
  "cloud",
  "donut",
  "pdf",
  "circleArrowRight",
  "circleArrowLeft",
  "trash",
  "ellipsisVertical",
  "sliders",
  "checkCircleSolid",
  "angleLeft",
  "angleDown",
  "angleUp",
  "xmark",
  "mouse",
  "play",
  "download",
  "copy",
  "gears",
  "shield",
  "globe",
  "clipboardList",
  "lightBulb",
  "universalAccess",
  "cloudSun",
  "seedling",
  "peopleGroup",
  "tableColumns",
  "layerGroup",
  "building",
  "user",
  "mail",
  "key",
  "eye",
  "eyeSlash",
  "powerOff",
  "dashboard",
  "schema",
  "products",
  "userCadre",
  "wallet",
  "lock",
  "plus",
  "doubleCheck",
  "magnifyingGlass",
  "check",
  "openLink",
  "warning",
  "print",
  "info",
  "tags",
  "certificate",
  "map",
  "circle",
  "minus",
  "circleUp",
  "circleDown",
  "exportFile",
  "circleExclamation",
  "italic",
  "bold",
  "alignLeft",
  "alignRight",
  "alignCenter",
  "list",
  "listOl",
] as const;

export type IconType = (typeof icons)[number] | Capitalize<(typeof icons)[number]>;

let svgIcons: { [k in IconType]?: LazyExoticComponent<() => JSX.Element> } = {};

icons.forEach(async (icon) => {
  svgIcons[icon] = lazy(() => import(`./icons/${capitalize(icon)}`));
});

// const icons = {
//   Search: lazy(() => import("./icons/Search")),
//   ArrowRight: lazy(() => import("./icons/ArrowRight")),
//   ArrowLeft: lazy(() => import("./icons/ArrowLeft")),
//   Bell: lazy(() => import("./icons/Bell")),
//   Snapchat: lazy(() => import("./icons/Snapchat")),
//   Facebook: lazy(() => import("./icons/Facebook")),
//   Instagram: lazy(() => import("./icons/Instagram")),
//   Linkedin: lazy(() => import("./icons/Linkedin")),
//   Twitter: lazy(() => import("./icons/Twitter")),
//   Tiktok: lazy(() => import("./icons/Tiktok")),
//   Pen: lazy(() => import("./icons/Pen")),
//   CheckCircle: lazy(() => import("./icons/CheckCircle")),
//   Cloud: lazy(() => import("./icons/Cloud")),
//   Donut: lazy(() => import("./icons/Donut")),
//   Pdf: lazy(() => import("./icons/Pdf")),
//   CircleArrowRight: lazy(() => import("./icons/CircleArrowRight")),
//   CircleArrowLeft: lazy(() => import("./icons/CircleArrowLeft")),
//   Trash: lazy(() => import("./icons/Trash")),
//   EllipsisVertical: lazy(() => import("./icons/EllipsisVertical")),
//   Sliders: lazy(() => import("./icons/Sliders")),
//   CheckCircleSolid: lazy(() => import("./icons/CheckCircleSolid")),
//   AngleLeft: lazy(() => import("./icons/AngleLeft")),
//   AngleDown: lazy(() => import("./icons/AngleDown")),
//   AngleUp: lazy(() => import("./icons/AngleUp")),
//   Xmark: lazy(() => import("./icons/Xmark")),
//   Mouse: lazy(() => import("./icons/Mouse")),
//   Play: lazy(() => import("./icons/Play")),
//   Download: lazy(() => import("./icons/Download")),
//   Copy: lazy(() => import("./icons/Copy")),
//   Gears: lazy(() => import("./icons/Gears")),
//   Shield: lazy(() => import("./icons/Shield")),
//   Globe: lazy(() => import("./icons/Globe")),
//   ClipboardList: lazy(() => import("./icons/ClipboardList")),
//   LightBulb: lazy(() => import("./icons/LightBulb")),
//   UniversalAccess: lazy(() => import("./icons/UniversalAccess")),
//   CloudSun: lazy(() => import("./icons/CloudSun")),
//   Seedling: lazy(() => import("./icons/Seedling")),
//   PeopleGroup: lazy(() => import("./icons/PeopleGroup")),
//   TableColumns: lazy(() => import("./icons/TableColumns")),
//   LayerGroup: lazy(() => import("./icons/LayerGroup")),
//   Building: lazy(() => import("./icons/Building")),
//   User: lazy(() => import("./icons/User")),
//   Mail: lazy(() => import("./icons/Mail")),
//   Key: lazy(() => import("./icons/Key")),
//   Eye: lazy(() => import("./icons/Eye")),
//   EyeSlash: lazy(() => import("./icons/EyeSlash")),
//   PowerOff: lazy(() => import("./icons/PowerOff")),
//   Dashboard: lazy(() => import("./icons/Dashboard")),
//   Schema: lazy(() => import("./icons/Schema")),
//   Products: lazy(() => import("./icons/Products")),
//   UserCadre: lazy(() => import("./icons/UserCadre")),
//   Wallet: lazy(() => import("./icons/Wallet")),
//   Lock: lazy(() => import("./icons/Lock")),
//   Plus: lazy(() => import("./icons/Plus")),
//   DoubleCheck: lazy(() => import("./icons/DoubleCheck")),
//   MagnifyingGlass: lazy(() => import("./icons/MagnifyingGlass")),
//   Check: lazy(() => import("./icons/Check")),
//   OpenLink: lazy(() => import("./icons/OpenLink")),
//   Warning: lazy(() => import("./icons/Warning")),
//   Print: lazy(() => import("./icons/Print")),
//   Info: lazy(() => import("./icons/Info")),
//   Tags: lazy(() => import("./icons/Tags")),
//   Certificate: lazy(() => import("./icons/Certificate")),
//   Map: lazy(() => import("./icons/Map")),
//   Circle: lazy(() => import("./icons/Circle")),
//   Minus: lazy(() => import("./icons/Minus")),
//   CircleUp: lazy(() => import("./icons/CircleUp")),
//   CircleDown: lazy(() => import("./icons/CircleDown")),
//   ExportFile: lazy(() => import("./icons/ExportFile")),
// };

type WrapProps = {
  hover?: Color;
  circle?: boolean;
  w?: number;
  c?: Color | string;
  disabled?: boolean;
};

const Wrap = styled.span
  .withConfig({
    shouldForwardProp: (prop) => isPropValid(prop),
  })
  .attrs<WrapProps>((p) => {
    return {
      hover: p.hover && typeof p.hover === "boolean" ? "gray200" : p.hover,
    };
  })`
  line-height: 0;
  transition: all ${(p) =>
    p.circle ? ".05" : ".1"}s cubic-bezier(0.4, 0, 1, 1);
  ${(p) =>
    p.hover &&
    css`
      cursor: pointer;
      width: calc(${p.circle ? "8px" : "16px"} + ${p.w}px);
      height: calc(${p.circle ? "8px" : "16px"} + ${p.w}px);
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      outline: ${p.theme.color[p.hover]}99 0px solid;
      transform: scale(1);
      &:hover {
        background-color: ${p.theme.color[p.hover]}cc;
        outline: ${p.theme.color[p.hover]}cc ${p.circle ? "4px" : "8px"} solid;
        transform: scale(0.96);
      }
      &:active {
        background-color: ${p.theme.color[p.hover]}99;
        outline: ${p.theme.color[p.hover]}99 5px solid;
        transform: scale(0.85);
      }
    `}
  svg {
    fill: ${(p) =>
      p.c && p.c.startsWith("#") ? p.c : p.theme.color[p.c || "gray300"]};
    width: ${(p) => p.w}px;
  }
  ${(p) =>
    p.circle &&
    css`
      width: ${p.w + 10 || 0 + 14}px;
      height: ${p.w + 10 || 0 + 14}px;
      display: inline-block;
      border-radius: 200px;
      background-color: ${p.c && p.c.startsWith("#")
        ? p.c
        : p.theme.color[p.c || "gray300"]};
      position: relative;
      svg {
        fill: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
  ${(p) =>
    p.disabled &&
    css`
      cursor: default;
      pointer-events: none;
      background-color: transparent;
      svg {
        fill: ${p.theme.color.gray400};
      }
    `}
`;

interface IconProps extends WrapProps, BaseHTMLAttributes<HTMLSpanElement> {
  name: IconType;
}

function Icon({ name, w = 18, c = "dark", hover, ...rest }: IconProps) {
  //@ts-ignore
  if (!icons.includes(decapitalize(name))) return;
  
  const Component = svgIcons[name];
  return (
    <Suspense fallback={<Loading size={w - 4} />}>
      <Wrap aria-label={`icon ${name}`} aria-disabled={rest.disabled ? true : false} {...{ w, c, hover, ...rest }}>
        <Component />
      </Wrap>
    </Suspense>
  );
}

export default Icon;
