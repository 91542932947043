import { useEffect, useState } from "react";
import Tiptap from "./Tiptap";
import { useRequest } from "services/request";
import { Box, Button, Card, InputText, Type } from "ui";
import { useUtils } from "services/utilsProvider";

export default function Certificate() {
  // const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);

  const [certificate, setCertificate] = useState({
    name: "",
    identity_address: "",
    verified_item_certificate: "",
  });

  const req = useRequest();
  const {notify} = useUtils();

  useEffect(() => {
    (async () => {
      const id = JSON.parse(window.localStorage.getItem("user"))
        .manufacturer.id;
      const resData = await req({
        url: `manufacturers/${id}/`, withAuth: true
      });
      setCertificate(resData);
      setFetching(false);
    })();
  }, [req]);

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };

  const handleSubmit = () => {
    (async () => {
      const id = JSON.parse(window.localStorage.getItem("user"))
        .manufacturer.id;
      try {
        setLoading(true);
        const resData = await req({
          url: `manufacturers/${id}/`,
          body: certificate,
          options: { method: "PATCH" },
          withAuth: true
        });
        setCertificate({ ...resData });
        // setOpen(true);
        notify("Saved successfully.", 'success')
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  };

  if (fetching) return null;

  return (



    <Box sx={{ maxw: 700 }}>
      <Card sx={{ mb: 3 }}>
        <Type var="h5" as="h5" sx={{ mb: 5 }}>Certificate</Type>
        <Type sx={{ mb: 10 }}>
          This is the brand information that you provided while creating your
          wallet.
        </Type>
        <Box>
          <InputText
            label="Name"
            name="name"
            onChange={(e: any) =>
              setCertificate((v) => ({
                ...v,
                name: e.target.value,
              }))
            }
            autoFocus
            value={certificate.name}
          />

          <InputText
            label="Identity Address"
            name="identity_address"
            value={certificate.identity_address}
            onChange={(e: any) =>
              setCertificate((v) => ({
                ...v,
                identity_address: e.target.value,
              }))
            }
          />

          <Box sx={{ mt: 20, mb: 15, fs: 14, c: "#444", fw: 500, pl: 2 }}>
            Verified Item Certificate
          </Box>

          <Tiptap
            content={certificate.verified_item_certificate + ""}
            onUpdate={(html: string) =>
              setCertificate((v) => ({
                ...v,
                verified_item_certificate: html,
              }))
            }
          />
        </Box>

        <Box
          sx={{ my: 20 }}
        >
          <Button
            // variant="outlined"
            // size="small"
            // iconName="pen"
            onClick={handleSubmit}
            disabled={loading}
            loading={loading}
          >
            Save
          </Button>

          {/* <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: "100%" }}
              >
                Saved successfully.
              </Alert>
            </Snackbar> */}
        </Box>
      </Card>
    </Box>
  );
}
