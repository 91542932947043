import {
  useEditor,
  EditorContent,
  // FloatingMenu,
  // BubbleMenu,
} from "@tiptap/react";
import TextAlign from "@tiptap/extension-text-align";
import Heading from "@tiptap/extension-heading";
import Paragraph from "@tiptap/extension-paragraph";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import { Box, Card, Divider, Icon, Type } from "ui";

// define your extension array
const extensions = [
  Text,
  Document,
  Heading,
  Paragraph,
  Bold,
  Italic,
  BulletList,
  ListItem,
  OrderedList,
  HorizontalRule,
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
];

const EditorItem = ({ label, handleClick, cond }: any) => {
  return (
    <Type
      as="div"
      sx={{
        fw: 500,
        bgc: cond ? "#d3e2ff" : "transparent",
        c: cond ? "#111" : "#888",
        h: 26,
        minw: 26,
        br: 4,
        cursor: "pointer",
        d: "flex",
        jc: "center",
        ai: "center",
        ":hover": {
          bgc: cond ? "#d3e2ff" : "#eee",
        },
      }}
      onClick={handleClick}
      // disabled={!editor.can().chain().focus().toggleBold().run()}
      className={cond ? "is-active" : ""}
    >
      {label}
    </Type>
  );
};

const Tiptap = ({ onUpdate, content }: any) => {
  const editor = useEditor({
    extensions,
    content: content,
    onUpdate: () => {
      onUpdate(editor.getHTML());
    },
  });

  return (
    <>
      <Box sx={{ p: 10, minh: 300, border: ['gray300', 1, 'solid', 5, 'tblr'] }}>
        <Box
          sx={{
            gap: "3px",
            d: "flex",
            ai: "center",
            mb: "3px",
          }}
        >
          <EditorItem
            label={<Icon name="bold" w={11} />}
            cond={editor.isActive("bold")}
            handleClick={() => editor.chain().focus().toggleBold().run()}
          />

          <EditorItem
            label={<Icon name="italic" w={11} />}
            cond={editor.isActive("italic")}
            handleClick={() => editor.chain().focus().toggleItalic().run()}
          />

          <EditorItem
            label={<Box sx={{ px: "5px" }}>H1</Box>}
            cond={editor.isActive("heading", { level: 1 })}
            handleClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
          />
          <EditorItem
            label={<Box sx={{ px: "5px", fs: 15 }}>H2</Box>}
            cond={editor.isActive("heading", { level: 2 })}
            handleClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
          />
          <EditorItem
            label={<Box sx={{ px: "5px", fs: 14 }}>H3</Box>}
            cond={editor.isActive("heading", { level: 3 })}
            handleClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
          />
          <EditorItem
            label={<Box sx={{ px: "5px", fs: 13 }}>H4</Box>}
            cond={editor.isActive("heading", { level: 4 })}
            handleClick={() =>
              editor.chain().focus().toggleHeading({ level: 4 }).run()
            }
          />
          <Divider vertical border="gray400" h={15} />
          <EditorItem
            label={<Icon name="alignLeft" w={14} />}
            cond={editor.isActive({ textAlign: "left" })}
            handleClick={() =>
              editor.chain().focus().setTextAlign("left").run()
            }
          />
          <EditorItem
            label={<Icon name="alignCenter" w={14} />}
            cond={editor.isActive({ textAlign: "center" })}
            handleClick={() =>
              editor.chain().focus().setTextAlign("center").run()
            }
          />
          <EditorItem
            label={<Icon name="alignRight" w={14} />}
            cond={editor.isActive({ textAlign: "right" })}
            handleClick={() =>
              editor.chain().focus().setTextAlign("right").run()
            }
          />
          <EditorItem
            label={<Icon name="list" w={16} />}
            cond={editor.isActive("bulletList")}
            handleClick={() => editor.chain().focus().toggleBulletList().run()}
          />
          <EditorItem
            label={<Icon name="listOl" w={16} />}
            cond={editor.isActive("orderedList")}
            handleClick={() => editor.chain().focus().toggleOrderedList().run()}
          />
          <Divider vertical border="gray400" h={15} />
          <EditorItem
            label={<Icon name="minus" w={14} />}
            cond={false}
            handleClick={() => editor.chain().focus().setHorizontalRule().run()}
          />
        </Box>

        <Divider border="gray300" />

        <EditorContent editor={editor} />
      </Box>
      {/* <FloatingMenu editor={editor}>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          bullet list
        </button>
      </FloatingMenu>
      <BubbleMenu editor={editor}>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          bullet list
        </button>
      </BubbleMenu> */}
    </>
  );
};

export default Tiptap;
